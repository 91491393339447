import React, { useState, useEffect } from "react";
import Select from 'react-select'
import DatePicker from 'react-datepicker'
// components
import CardStation from './CardStation';
import UserData from '../../services/user/userData'



export default function CardSettings() {
  const [mode, setMode] = useState("1")
  const [devise, setDevise] = useState("1")
  const [userStations, setUserStations] = useState([])

  const [startDate, setStartDate] = useState(new Date());

  const onModeChange = event => {
    setMode(event.value);
    console.log(event.value)
  }

  const onDeviseChange = event => {
    setDevise(event.value);
    console.log(event.value)
  }

  const getUserData = () => {
    UserData.getUserData({
      ID: localStorage.getItem('idClient'),
    })
    .then(res => {
        console.log(res.data)
        if(res.data.data && res.data.data.stations)
        {
          console.log(res.data.data.stations)
          setUserStations(res.data.data.stations)
        }
    })
    .catch(error => console.error("errr"+error))
  }
  
  const options = [
    { value: '1', label: 'Tariffication Simple' },
    { value: '2', label: 'Tariffication Horaire' },
  ]

  const optionsDevise = [
    { value: '1', label: '$' },
    { value: '2', label: '£' },
    { value: '2', label: 'MAD' },
  ]

  useEffect(() => {
    getUserData()
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Update
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Les stations
            </h6>
            
            {userStations.map(({nomeStation,id})=>{

              return <CardStation  NomeStation={nomeStation} IdStation={id} />

            })}

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Tariffication
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mode
                  </label>
                  <Select options={options} defaultValue={options[0]} onChange={onModeChange}/>
                
                </div>
              </div>
            
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Les prix
            </h6>
            <div className="flex flex-wrap">
              {mode == '1'  ? (
                <>
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prix du KWh
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="0.00"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Devise
                  </label>
                  <Select options={optionsDevise} defaultValue={optionsDevise[0]} onChange={onDeviseChange}/>
                </div>
              </div>
              </>
              ) : (
                <>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Heures normales
                  </label>
                    <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    // isClearable={true}
                   />
                  
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="United States"
                  />
                </div>
              </div>
              {/* <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue="Postal Code"
                  />
                </div>
              </div> */}
              </>
              ) }
            </div>

           
           
          </form>
        </div>
        <div className="flex flex-wrap">

       
        </div>
      </div>
    </>
  );
}
